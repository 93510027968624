<template>
  <div>
    <YzgHeader></YzgHeader>
    <div class="box_view" v-if="this.video_src">
      <iframe class="middleView" :src="video_src" frameborder="0"></iframe>
    </div>
    <div class="boxpos">
      <div :class="this.video_src === '' ? 'hotel_titles' : 'hotel_title'">​​{{article_title}}</div>
    </div>
    <div class="boxpos">
      <img class="mark"
           src="../assets/pic/mark.png"
           alt="">
    </div>
    <div class="manager_comment">{{ comment }}</div>
    <div v-for="(item, index) in currentList"
         :key="index"
         class="instuctions_detail">
      <div class="hotel_details">
        <pre style="white-space:pre-wrap" v-if="item.paragraph_title" class="paragraph_headings">{{ item.paragraph_title}}</pre>
        <pre style="white-space:pre-wrap" class="aticle_content">{{ item.paragraph_content}}</pre>
        <div v-if="item.paragraph_image.length > 0" style="margin-top: 40px">
                     <img v-for="(k, j) in item.paragraph_image" :key="j" class="scenery_hotel" :src="k" >
        </div>
      </div>
    </div>
    <YzgFooter></YzgFooter>
  </div>
</template>

<script>
import axios from 'axios'
import YzgHeader from "@/components/YzgHeader"
import YzgFooter from "@/components/YzgFooter"
// import Video from "@/components/Video"
// import Details from "@/components/Details"
export default {
  name: 'detailCase',
  components: {
    YzgHeader,
    YzgFooter,
    // Video,
    // Details,
  },
  created () {
    this.getdetailshotel()
  },
  data () {
    return {
      // Video:'Video',
      // Details:'Details'
      // 具体数据列表
      currentList: [],
      //文章标题
      article_title: '',
      // 视频地址
      video_src: '',
      // 掌柜评论
      comment: '',
      testEnv:"https://testm.miot.com.cn/",
      proEnv:"https://m.yunzhanggui.net/"
    };
  },
  methods: {
    // 发送请求获取酒店详情
    async getdetailshotel () {
      console.log(this.$route.meta.title, '标题')
      this.$route.meta.title = this.$route.meta.title +  '-' + this.$route.query.title
      try {
        const res = await axios.get(`${this.proEnv}apiv1/official_web_manage.php?action=getinfobyid&key=miotoa&id=${this.$route.query.id}`)
        console.log(res, '获取酒店详情');
        this.currentList = res.data.paragraph_info_list
        this.article_title = res.data.article_title
        this.comment = res.data.comment
        this.video_src = res.data.video_src
      } catch (error) {
        return
      }
    }
  },

  //   computed: {
  //   videoFlag:{
  //     get(){
  //       return this.$route.query.title == '客户案例-大理云端连锁' ? false : true
  //     }
  //   }
  // },
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
@import url("../assets/index.css");
.red {
  color: red;
}
.middleView {
  width: 736px;
  height: 414px;
  margin: 0 auto;
  margin-top: 145px;
  background: linear-gradient(225deg, #fff1ea 0%, #dcfaf7 100%);
}
.hotel_details {
  width: 928px;
  /*height: 100%;*/
  margin: 0 auto;
}
.hotel_title {
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 40px;
  /*margin-left: 485px;*/
  margin-top: 280px;
}
.hotel_titles{
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 40px;
  /*margin-left: 485px;*/
  margin-top: 120px;
}
.mark {
  display: block;
  width: 24px;
  height: 18px;
  margin-top: 32px;
}

.paragraph_headings {
  /*height: 32px;*/
  font-size: 32px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #595959;
  line-height: 32px;
  margin-top: 120px;
  overflow: hidden;
}
.manager_comment {
  width: 928px;
  height: 84px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262626;
  line-height: 28px;
  margin: 0 auto;
  margin-bottom: 120px;
}
.aticle_content {
  width: 928px;
  /*height: 84px;*/
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
  line-height: 28px;
  margin-top: 40px;
  overflow: hidden;
}
.scenery_hotel {
  display: block;
  width: 736px;
  /*height: 414px;*/
  background: black;
  margin: 0 auto;
  margin-top: 10px;
}
.pic_text {
  width: 736px;
  height: 16px;
  font-size: 16px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #595959;
  line-height: 16px;
  margin: 0 auto;
  margin-left: 352px;
  margin-top: 24px;
}
  .box_view {
    margin: 0 auto;
    width: 736px;
    height: 414px;
    margin-bottom: 120px;
  }
  .boxpos {
    position: relative;
    width: 928px;
    margin: 0 auto;
  }

</style>
